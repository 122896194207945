<template>
    <div class="dashboard cust-page-search">
        <h1 class="l-mt2 l-mb5">
            <img src="@/assets/images/title.png" alt="名古屋市認知症初期集中支援チーム 支援計画書検索システム">
        </h1>
        <p>キーワード</p>
        <div class="cust-page-search__input">
            <input v-model="params.search_keywords" type="search">
            <button class="button--oval--primary" type="button" @click="historyPush">検索</button>
        </div>
        <div class="l-mb4 cust-page-search__select">
            <div class="l-mr3">
                <p>チーム選択</p>
                <select v-model="params.team_id">
                    <option value="">選択してください。</option>
                    <option v-for="(data, index) in sortingListTeams" :key="`option${index}`" :value="data.team_id">{{ data.team_name }}</option>
                </select>
            </div>
            <div>
                <p>検索期間（支援終了日）</p>
                <div class="style-select-term">
                    <input v-model="params.start_date" type="date">
                    <span>～</span>
                    <input v-model="params.end_date" type="date">
                </div>
            </div>
        </div>
        <table class="cust-table">
            <tr>
                <th v-for="data in tableSet" :key="data.key">
                    {{ data.head }}
                </th>
            </tr>
            <router-link v-for="(list, index) in sortingListSupportDetails" :key="`list${index}`" :to="{ name: 'support_data', params: { id: String(list.id), creator: String(list.team_name) }, query: { search: params.search_keywords }}" tag="tr">
                <td v-for="data in tableSet" :key="`list${index}${data.key}`" :class="data.class" v-html="list[data.key]"></td>
            </router-link>
        </table>
        <Modal :modal="modal" @action="modalAction($event)" @close="$_modalClose()" />
    </div>
</template>

<script>
    "use strict";

    import apiListTeams from "@/mixins/apiListTeams";
    import common from "@/mixins/common";
    import sorts from "@/mixins/sort";

    const tableSearch = [
        {
            "class": "",
            "head": "チーム名",
            "key": "team_name",
        },
        {
            "class": "",
            "head": "支援終了日",
            "key": "support_end_date",
        },
        {
            "class": "is-wrap cust-table__link",
            "head": "ケースのタイトル",
            "key": "case_title",
        },
    ];

    export default {
        "mixins": [
            apiListTeams,
            common,
            sorts,
        ],
        data() {
            return {
                "listSupportDetails": [],
                "params": {
                    "team_id": "",
                    "start_date": "",
                    "end_date": "",
                    "search_keywords": "",
                },
                "tableSet": tableSearch,
            };
        },
        "computed": {
            sortingListSupportDetails() {
                // 支援終了日で降順
                const list = this.listSupportDetails;
                const key = "support_end_date";
                return this.$_sorting(list, key, "desc");
            },
            sortingListTeams() {
                // チームIDで昇順
                const list = this.listTeams;
                // const key = "team_id";
                // return this.$_sorting(list, key);
                return list;
            },
        },
        created() {
            this.$_fetchingListTeams();
            if (this.$route.fullPath !== "/search/" && this.$route.fullPath !== "/search") {
                this.fetchingSupportData();
            }
        },
        "methods": {
            getParams() {
                for (let key in this.params) {
                    this.params[key] = this.$route.query[key];
                }
            },
            historyPush() {
                // ブラウザ履歴に登録
                this.$router.push({ "name": "search", "query": this.params }, () => {}, () => {});
                this.fetchingSupportData();
            },
            fetchingSupportData() {
                // クエリからパラメータを取得
                this.getParams();
                // 支援記録データ検索
                common.$_axios.get(
                    `${common.$_configs.apiUrl}/support_data`,
                    this.$_requestConfig("params")
                ).then(response => {
                    let responseData = response.data;
                    if (this.params.search_keywords) {
                        const searchKeywords = this.params.search_keywords.replace(/ /g, "　").split("　");
                        for (let key in responseData) {
                            for (let keyword of searchKeywords) {
                                if (keyword !== "") {
                                    if (responseData[key].case_title) {
                                        const textData = String(responseData[key].case_title);
                                        responseData[key].case_title = textData.replace(new RegExp(keyword, "g"), `<strong class="marker">${keyword}</strong>`);
                                    }
                                }
                            }
                        }
                    }
                    this.listSupportDetails = responseData;
                }).catch(() => {
                    this.$_modalError("supportDetailsSearchError");
                });
            },
        },
    };
</script>
